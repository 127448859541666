<template>
  <div class="overflow-hidden clear-both">
    <!-- <img class="demo" src="/img/demo/pro.png" /> -->
    <img class="block p1" src="/img/pro/1.png" />
    <div class="overflow-hidden float-right relative p2">
      <img class="block" src="/img/pro/2.png" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/pro/2-1.png" />
      </div>
    </div>
    <div class="overflow-hidden float-left relative p3">
      <img class="block p3" src="/img/pro/3.png" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/pro/3-1.png" />
      </div>
    </div>

    <div class="overflow-hidden float-right relative p4">
      <img class="float-right block" src="/img/pro/4.png" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/pro/4-1.png" />
      </div>
    </div>
    <div class="overflow-hidden float-left relative p5">
      <img class="float-left block" src="/img/pro/5.png" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/pro/5-1.png" />
      </div>
    </div>

    <div class="overflow-hidden float-right relative p6">
      <img class="float-right block" src="/img/pro/6.png" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/pro/6-1.png" />
      </div>
    </div>
    <div class="overflow-hidden float-left relative p7">
      <img class="block" src="/img/pro/7.png" />
    </div>

    <div class="overflow-hidden float-left relative p8">
      <img class="block" src="/img/pro/8.png" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/pro/8-1.png" />
      </div>
    </div>
    <div class="overflow-hidden float-left p9" id="pro_p9">
      <img class="block" src="/img/pro/9.png" />
    </div>

    <div class="overflow-hidden float-left relative p10">
      <img class="block" src="/img/pro/10.png" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/pro/10-1.png" />
      </div>
    </div>
    <div class="float-right p11 w-full">
      <div class="float-right relative">
        <img class="float-right block" src="/img/pro/11.png" />
        <div class="absolute">
          <img data-aos="fade-up" class="block" src="/img/pro/11-1.png" />
        </div>
      </div>
    </div>

    <div class="overflow-hidden float-left relative p12">
      <img class="block" src="/img/pro/12.png" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/pro/12-1.png" />
      </div>
    </div>
    <div class="overflow-hidden relative float-left p13">
      <img class="block" src="/img/pro/13.png" />
    </div>
  </div>
</template>

<script>
  import aos from '@/mixins/aos'
  import jump from '@/mixins/jump'
  export default {
    mixins: [aos, jump],
  }
</script>

<style lang="scss" scoped>
  .p2 {
    margin-top: 70px;
    & > img {
      width: 1687px;
    }
    .absolute {
      top: 31.3%;
      left: 0;
      width: 158px;
    }
  }
  .p3 {
    & > img {
      width: 1747px;
      margin-top: 69px;
    }
    .absolute {
      top: 23.6%;
      left: 1118px;
      width: 158px;
    }
  }
  .p4 {
    margin-top: 89px;
    & > img {
      width: 1789px;
    }
    .absolute {
      top: 1.8%;
      left: 100px;
      width: 157px;
    }
  }
  .p5 {
    margin-top: 31px;
    & > img {
      width: 1920px;
    }
    .absolute {
      top: 34.6%;
      left: 58.8%;
      width: 158px;
    }
  }
  .p6 {
    margin-top: 71px;
    & > img {
      width: 1920px;
    }
    .absolute {
      top: 15.4%;
      left: 208px;
      width: 263px;
    }
  }
  .p7 {
    margin-top: 70px;
  }
  .p8 {
    margin-top: 111px;
    & > img {
      width: 1920px;
    }
    .absolute {
      top: 0;
      left: 46%;
      width: 157px;
    }
  }
  .p9 {
    margin-top: 57px;
  }
  .p10 {
    margin-top: 112px;
    & > img {
      margin-left: 116px;
      width: 1599px;
    }
    .absolute {
      top: 19.6%;
      left: 1135px;
      width: 157px;
    }
  }
  .p11 {
    margin-top: 125px;
    background-color: #f1f1f9;
    .relative > img {
      width: 1707px;
    }
    .absolute {
      top: 25.4%;
      left: 0;
      width: 236px;
    }
  }
  .p12 {
    margin-top: -10px;

    & > img {
      width: 1920px;
    }
    .absolute {
      top: 30.7%;
      left: 58.9%;
      width: 158px;
    }
  }
  .p13 {
    margin-bottom: 150px;
  }
</style>
